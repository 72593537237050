import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Import the Lightbox CSS

const SmoothGallery = () => {
  const images = [
    { src: "/images/Schneepiste.jpg", alt: "Schneepiste", title: "Schneepiste" },
    { src: "/images/Praeparierung.jpeg", alt: "Praeparierung", title: "Praeparierung" },
    { src: "/images/Skidu.jpeg", alt: "Skidu", title: "Skidu" },
    { src: "/images/Huettenwagen.jpg", alt: "Hüttenwagen", title: "Hüttenwagen" },
  ];

  // State to track the currently selected image index and lightbox open state.
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="app-container">
      <div className="impressionen-content">
        <h1 id="section1" style={{ paddingBottom: "30px" }}>
          Impressionen und Geschichte (folgt)
        </h1>
        <div className="image-grid">
          {images.map((image, i) => (
            <div
              className="image-container"
              key={i}
              onClick={() => {
                setPhotoIndex(i);
                setIsOpen(true);
              }}
            >
              <img src={image.src} alt={image.alt} />
              <div className="image-title">{image.title}</div>
            </div>
          ))}
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex].src}
            nextSrc={images[(photoIndex + 1) % images.length].src}
            prevSrc={
              images[(photoIndex + images.length - 1) % images.length].src
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % images.length)
            }
            imageCaption={images[photoIndex].title}
          />
        )}
      </div>
    </div>
  );
};

export default SmoothGallery;
