import React from 'react';
export default function Anfahrt() {
  return (
    <div className="app-container">
      <div className="content">
        <h1 className="title">Anfahrt</h1>
        <h2 className="subtitle">Komm und besuche uns!</h2>
        <p className="text">
          Wir freuen uns sehr über jeden, der uns besuchen kommt. Wenn du planst, vorbeizukommen, dann interagiere gerne mit der Seite unten, um zu sehen, wo wir uns befinden. HINEWEIS(!) Durch das Interagieren mit der unten liegenden Karte erklären Sie sich mit der <a href={`${process.env.PUBLIC_URL}/datenschutz`} class='data'>Datenschutzerklärung</a>  einverstanden.
        </p>
        

        <iframe 
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2587.7994644153505!2d9.545726212970106!3d49.56380685068587!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4798093a2a027f33%3A0x9cf604b2512c20ae!2sSki-Club%20Buch!5e0!3m2!1sde!2sde!4v1698682127687!5m2!1sde!2sde" 
    style={{ 
      display: 'block', 
      height: '500px', // Changed to 500px
      width: '100%', 
      margin: '50px auto 0 auto', 
      border: 0 
    }}
    loading="lazy" 
    referrerPolicy="no-referrer-when-downgrade"
    title="Ski-Club Buch Map">
</iframe>


      </div>
    </div>
  );
}