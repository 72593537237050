import React from 'react';
import '../Cards.css';
import CardItem from '../CardItem';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Freizeiten() {
  return (
    <div className="app-container">
      <div className="content">
        {/* Main Header & Intro */}
        <h1 className="title">Ausflüge</h1>
        <p className="text">
          In der Vergangenheit haben wir viele spannende Ausflüge unternommen und auch in der Zukunft stehen einige auf dem Programm. Hier findest du eine Übersicht über unsere vergangenen und zukünftigen Ausflüge.
        </p>
                {/* Section for Upcoming Excursions */}
                <h2>Zukünftige Ausflüge</h2>
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
              <CardItem
                src="images/montafon_setiz.jpeg"
                text="Skiausflug von Seitz 08.02.2025"
                alt="Skiausflug von Seitz 08.02.2025"
                label="Geplant"
                path="/ausfluege/zukuenftig/skiausflug"
              />
            </ul>
          </div>
        </div>
        {/* Section for Past Excursions */}
        <h2>Vergangene Ausflüge</h2>
        <div className="cards__container">
          <div className="cards__wrapper">
            <ul className="cards__items">
              <CardItem
                src="images/pitztal_2025.png"
                text="Skiausflug Januar 2025"
                alt="Skiausflug Januar 2025"
                label="Vergangen"
                path="/freizeit/vergangen/skiausflug"
              />
            </ul>


          </div>
        </div>



        {/* Social Media Links */}
        <div className="download-section">
          <Link
            to="https://www.facebook.com/SkiClubBuch/?locale=de_DE"
            target="_blank"
            className="download"
            style={{ backgroundColor: "#3b5998" }}
          >
            <FaFacebook style={{ marginRight: "10px" }} />
            Facebook
          </Link>
          <Link
            to="https://www.instagram.com/"
            target="_blank"
            className="download"
            style={{ backgroundColor: "#E1306C" }}
          >
            <FaInstagram style={{ marginRight: "10px" }} />
            Instagram
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Freizeiten;
