import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1 id='section1'>Lerne unseren Verein kennen</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/verein.jpg'
              text='Werde Teil des Vereins'
              alt='Menschen in Skilift'
              label='Mitgliedsantrag'
              path='/mitgliedsantrag'
            /> 
            <CardItem
              src='images/Huettenwagen.jpg'
              text='Entdecke unser Angebot'
              alt='Preisliste'
              label='Leistungen'
              path='/leistungen'
            />
            </ul>
            <ul className='cards__items'>
            <CardItem
              src='images/Anfahrt.png'
              text='Komm vorbei'
              label='Anfahrt'
              path='/anfahrt'
            />
            <CardItem
              src='images/Freizeit.jpg'
              text='Freue dich auf Ausflüge, Veranstaltungen und Freizeiten'
              label='Ausflüge'
              path='/freizeit'
            />
            <CardItem
              src='images/Historie.jpg'
              text='Eindrücke und Entstehung'
              label='Eindrücke'
              path='/impressionen'
            />
           
         </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;