import React from 'react';

const Angebotseite = () => {
  return (
    <div className="app-container">
      <div className="content">
        <h1 className="title">Angebote Skiclub</h1>
        <p className="text">Entdecken Sie unsere Angebote und genießen Sie einen unvergesslichen Tag auf unseren Pisten. Sowohl für das leibliche Wohl, als auch für den Spaß auf der Piste ist gesorgt!
        <br /> 
        Bitte beachten Sie, dass für die Nutzung des Skilift eine Tagesmitgliedschaft notwendig ist. Diese Regelung basiert auf versicherungstechnischen Gründen und dient Ihrer eigenen Sicherheit.</p>
        
        <table>
          <tr>
            <th>Produkt</th>
            <th>Preis</th>
          </tr>
          <tr>
            <td>Glühwein</td>
            <td>3,50 €</td>
          </tr>
          <tr>
            <td>Bier</td>
            <td>3,00 €</td>
          </tr>
          <tr>
            <td>Softgetränke</td>
            <td>2,50 €</td>
          </tr>
          <tr>
            <td>Würstchen</td>
            <td>115,00 €</td>
          </tr>
          <tr>
            <td>Kuchen</td>
            <td>2,50 €</td>
          </tr>
          <tr>
            <td>Tagesmitgliedschaft Kind</td>
            <td>3,00 €</td>
          </tr>
          <tr>
            <td>Tagesmitgliedschaft Erwachsener</td>
            <td>5,00 €</td>
          </tr>
        </table>
      </div>
    </div>
  );
};
export default Angebotseite;