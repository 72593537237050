import {React} from 'react';

export default function Mitgliedsantrag() {


    return (
        <>
        <div className='app-container'>
            <div className='content'>
              <h1  className='title'>Mitgliedsantrag</h1>
              <h2 className='subtitle'>Warum sollten Sie Mitglied werden?</h2>
              <p className='text'>
                Als Mitglied unseres Vereins können Sie aktiv dazu beitragen, unsere Ziele zu erreichen. 
                Sie profitieren auch von persönlichen Vorteilen wie exklusiven Zugang zu unseren Veranstaltungen, 
                die Möglichkeit, sich mit anderen Mitgliedern zu vernetzen und vielem mehr. 
                Ihre Mitgliedschaft wird anderen Mitgliedern und der Gemeinschaft insgesamt zugute kommen. 
                Schließen Sie sich uns an und machen Sie einen Unterschied!
              </p>
              <div className='download-section'>


                  <a href={`${process.env.PUBLIC_URL}/Mitgliedsantrag.pdf`} download className='download'>Download Mitgliedsantrag (PDF)</a> 
              </div>
              <p className='text'>Bitte senden sie den ausgefüllten Mitgliedsantrag an unsere Vorstandschaft unter folgender Emailadresse:</p>
            </div>
        </div>
        </>   
    )
}