import React from 'react';
import { Link } from 'react-router-dom';
import '../Cards.css';

function VergangeneSkifreizeit() {
  return (
    <div className="app-container">
      <div className="content">
        <h1 className="title">Skifreizeit Januar 2025 – Rückblick</h1>
        
        {/* Flyer Image */}
        <div className="flyer-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src="/images/pitztal_2025.png" alt="Flyer Skiausflug Januar 2025" className="flyer-image" style={{ maxWidth: '40%', height: 'auto',  marginBottom: '3rem'}} />
        </div>
        
        {/* Event Summary */}
        <p className="text">
          Die vergangene Skifreizeit war ein voller Erfolg! Alle Teilnehmer hatten großartige Tage im Pitztal 
          mit perfekten Schneebedingungen und viel Sonnenschein. Das Hotel bot ausgezeichneten Komfort, 
          und auch die Organisation der Busfahrt ließ keine Wünsche offen.
        </p>
        
        <p className="text">
          Besonders erfreulich war die Kinderbetreuung, die sowohl innerhalb als auch außerhalb der Skikurse
          durch zahlreiche freiwillige Helfer bestens unterstützt wurde. Alle Kinder hatten viel Spaß,
          haben tolle Fortschritte gemacht und eine großartige Zeit miteinander verbracht.
        </p>
        
        <p className="text">
          Ein weiteres Highlight: Dank der positiven Atmosphäre und des gemeinschaftlichen Erlebnisses konnten 
          wir direkt einige neue Mitglieder für unseren Verein gewinnen! 🎿🎉
        </p>

        {/* Back Link */}
        <div className="back-link" style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          <Link to="/freizeit" className="button" style={{ padding: '0.75rem 1.5rem', backgroundColor: 'var(--primary-color)', color: '#fff', borderRadius: '8px', textDecoration: 'none', transition: 'background 0.3s, transform 0.3s' }}>
            Zurück zu den Ausflügen
          </Link>
        </div>
      </div>
    </div>
  );
}

export default VergangeneSkifreizeit;
